import { ApiResponse, getRequest, patchRequest, QueryParams } from './request';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  BookAccount,
  BookAccountMapping,
  BAMappingCounts,
  UpdateBookAccountMappingDto,
  BookAccountMappingRow,
  UseBookAccountsData,
  BOOK_ACCOUNT_ARCHIVED_GROUP_NAME,
} from './__types/book-accounts.types';

// MISC
import { groupBy, keyBy, mapValues } from 'lodash-es';

// Types
export * from './__types/book-accounts.types';

export const getBankBookAccounts = (params?: QueryParams) => {
  return getRequest<ApiResponse<BookAccount[]>>('/accounting/accounts', params);
};

export const getBookAccountMappings = () => {
  return getRequest<ApiResponse<BookAccountMapping[]>>('/accounting/book-account-mappings', {});
};

export const getBookAccountMappingCounts = () => {
  return getRequest<ApiResponse<BAMappingCounts>>('/accounting/book-account-mappings/counts', {});
};

export const patchBookAccountMappings = (dto: UpdateBookAccountMappingDto[]) => {
  return patchRequest<ApiResponse<BookAccountMapping[]>>('/accounting/book-account-mappings', dto);
};

export const useBookAccounts = () => {
  const bookAccountQuery = useQuery<UseBookAccountsData, Error>({
    queryKey: ['bookAccounts'],
    queryFn: async () => {
      const { body } = await getBankBookAccounts();

      const list = body.data as BookAccount[];
      const byId = keyBy(list, 'id');

      /*
       * Group the book accounts by their GL Type
       */
      const groupedByGLType = groupBy(
        list.filter((_ba) => !!_ba.isEnabled),
        (bookAccount) => bookAccount.fullyQualifiedCategory.split('.')[0],
      );

      // Add disabled items
      groupedByGLType[BOOK_ACCOUNT_ARCHIVED_GROUP_NAME] = list.filter((_ba) => !_ba.isEnabled);

      return {
        list,
        byId,
        groupedByGLType: mapValues(groupedByGLType, (group) =>
          group.sort((a, b) => (a.code || a.name).localeCompare(b.code || b.name)),
        ),
      };
    },
    placeholderData: { byId: {}, list: [], groupedByGLType: {} },
    staleTime: 1000 * 60 * 5,
  });

  return {
    ...bookAccountQuery,
    query: bookAccountQuery,
    byId: bookAccountQuery.data?.byId as Record<string, BookAccount>,
    list: bookAccountQuery.data?.list as BookAccount[],
    groupedByGLType: bookAccountQuery.data
      ?.groupedByGLType as UseBookAccountsData['groupedByGLType'],
  };
};

export const baMappingCountsQueryKey = ['bookAccountMappingCounts'];

export const useBookAccountMappingCounts = () => {
  const query = useQuery<BAMappingCounts, Error>({
    queryKey: baMappingCountsQueryKey,
    queryFn: async () => {
      const { body } = await getBookAccountMappingCounts();
      return body.data;
    },
    placeholderData: {
      unmappedCount: 0,
      totalAssets: 0,
    },
    cacheTime: 1000 * 60 * 20,
    staleTime: 1000 * 60 * 15,
  });

  return {
    unmappedCount: query.data?.unmappedCount ?? 0,
    totalAssets: query.data?.totalAssets ?? 0,
  };
};

export const getBAMappingQueryKey = (strategy?: 'ByAsset' | 'ByWallet') => {
  const key = ['bookAccountMappings'];
  if (strategy) key.push(strategy);
  return key;
};

export const useBookAccountMappings = (strategy?: 'ByAsset' | 'ByWallet') => {
  const baMappingQueryKey = getBAMappingQueryKey(strategy);

  // fetch the mappings
  const baMappingsQuery = useQuery<BookAccountMappingRow[], Error>({
    queryKey: baMappingQueryKey,
    queryFn: async () => {
      const { body } = await getBookAccountMappings();
      return (
        body.data?.map((mapping: BookAccountMapping) => ({
          ...mapping,
          dataPath: mapping.parent ? [mapping.parent, mapping.id] : [mapping.id],
        })) ?? []
      );
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 3, // keep in cache for 3 minutes
    enabled: !!strategy,
  });

  return {
    query: baMappingsQuery,
    data: baMappingsQuery.data as BookAccountMappingRow[],
  };
};

export const useUpdateBookAccountMappings = (strategy?: 'ByAsset' | 'ByWallet') => {
  const baMappingQueryKey = getBAMappingQueryKey(strategy);
  const queryClient = useQueryClient();

  // mutation for updating mappings
  const mutation = useMutation({
    mutationFn: async (dto: UpdateBookAccountMappingDto[]) => {
      const { body } = await patchBookAccountMappings(dto);
      return body.data;
    },
    onSuccess: (data: BookAccountMapping[]) => {
      // update the cache with the new data
      queryClient.invalidateQueries(baMappingCountsQueryKey);
      queryClient.setQueryData(baMappingQueryKey, (state: BookAccountMappingRow[] | undefined) => {
        const updatesById = keyBy(data, 'id');
        return (
          state?.map((row: BookAccountMappingRow) => {
            const updatedRow = updatesById[row.id];
            if (updatedRow) {
              return { ...updatedRow, dataPath: row.parent ? [row.parent, row.id] : [row.id] };
            }
            return row;
          }) ?? []
        );
      });
    },
  });

  return mutation;
};
