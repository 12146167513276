import { DefiBalancesQuery } from './accounts';
import { getRequest, postRequest } from './request';
import { useQuery } from '@tanstack/react-query';
import { AssetRollForwardRealizedGainLossReportDto } from '@/pages/reports/fasb/fasb.types';

export interface GainLossReportAssetDto {
  from?: string;
  to?: string;
  tokenIds?: string[];
}
export interface GainLossReportAccountDto {
  from?: string;
  to?: string;
  tokenIds?: string[];
  accounts?: string[];
}

export interface GainLossReportExportDto {
  from?: string;
  to?: string;
  tokenIds?: string[];
  accounts?: string[];
}

export interface ExportGainLossto {
  from?: string;
  to?: string;
  tokenIds?: string[] | number[];
  accounts?: string[];
  nftCollectionIds?: string[];
}
export interface CostBasisto {
  from?: string;
  to?: string;
  tokenIds?: string[];
  remainingValue?: string;
}

// Tokens tab
type ExportClosingPositionsTokensReportParams = {
  endpoint: 'asset';
  dto: {
    date?: string;
    tokens?: string;
  };
};

// NFT Collections tab
type ExportClosingPositionsNftCollectionsReportParams = {
  endpoint: 'nft';
  dto: {
    date?: string;
    collections?: string[];
  };
};

// Wallets tab
type ExportClosingPositionsWalletsReportParams = {
  endpoint: 'account';
  dto: {
    date?: string;
    accounts?: string[];
    collections?: string[];
    tokens?: string;
  };
};

// DeFi tab
type ExportClosingPositionsDefiReportParams = {
  endpoint: 'defi';
  dto: DefiBalancesQuery;
};

export type ExportClosingPositionsReportParams =
  | ExportClosingPositionsTokensReportParams
  | ExportClosingPositionsNftCollectionsReportParams
  | ExportClosingPositionsWalletsReportParams
  | ExportClosingPositionsDefiReportParams;

export const reportsQueryKeys = {
  all: ['reports'] as const,
  lists: () => [...reportsQueryKeys.all, 'lists'] as const,

  fasbLists: () => [...reportsQueryKeys.all, 'fasbLists'] as const,
  fasbRollForward: (dto: GainLossReportAccountDto) =>
    [...reportsQueryKeys.fasbLists(), dto] as const,
};

export const exportClosingPostionsReport = ({
  endpoint,
  dto,
}: ExportClosingPositionsReportParams) =>
  getRequest<string>(`/reports/closing-positions/${endpoint}`, dto, {
    Accept: 'application/json,text/csv',
  });

export const exportCostBasis = (dto: CostBasisto) =>
  postRequest<string>(`/reports/tax-lot`, dto, { Accept: 'application/json,text/csv' });

export const exportGainLoss = (dto: ExportGainLossto) =>
  postRequest<string>(`/reports/gain-loss`, dto);

export const exportRealizedGainLoss = (endpoint: string, dto: GainLossReportExportDto) =>
  getRequest<string>(`/reports/realized-gain-loss/${endpoint}`, dto, {
    Accept: 'text/csv',
  });

export const getGainLossAsset = (dto: GainLossReportAssetDto) =>
  getRequest<string>(`/reports/realized-gain-loss/asset`, dto);

export const getGainLossAccount = (dto: GainLossReportAccountDto) =>
  getRequest<string>(`/reports/realized-gain-loss/account`, dto);

export const downloadReport = (id: string) =>
  getRequest<string>(`/reports/${id}`, {}, { Accept: 'text/csv' });

export const getReportHistory = () => getRequest<string>(`/reports/history`, {});

export const exportAccounts = () =>
  postRequest<string>('/reports/accounts', {}, { Accept: 'text/csv' });

export const exportContacts = () =>
  postRequest<string>('/reports/contacts', {}, { Accept: 'text/csv' });

export const exportRoyaltyInvoiceTransactions = (dto: { invoiceId: string }) =>
  postRequest<string>('/reports/royalty-invoice-transactions', dto, { Accept: 'text/csv' });

export const _getFasbRollForward = (dto: GainLossReportAccountDto) =>
  getRequest<string>(`/reports/fasb/roll-forward`, dto);
export const useGetFasbRollForward = (dto: GainLossReportAccountDto) => {
  return useQuery<AssetRollForwardRealizedGainLossReportDto[], Error>({
    enabled: !!dto.from && !!dto.to,
    queryKey: reportsQueryKeys.fasbRollForward(dto),
    queryFn: async () => {
      const { body } = await _getFasbRollForward(dto);
      return body.data;
    },
  });
};

export const exportFasbRollForward = (dto: GainLossReportAccountDto) =>
  getRequest<string>('/reports/fasb/roll-forward', dto, { Accept: 'text/csv' });
